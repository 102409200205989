@font-face {
  font-family: 'SFPro';
  src: url('./assets/fonts/SFProDisplay-Regular.ttf') format('truetype');
  /* Safari, Android, iOS */
  font-weight: normal;
}

@font-face {
  font-family: 'SFPro';
  src: url('./assets/fonts/SFProDisplay-Bold.ttf') format('truetype');
  /* Safari, Android, iOS */
  font-weight: bold;
}

* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  color: inherit;
  font-family: 'Josefin Sans', sans-serif;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

*,
[class^="MuiTypography"] {
  font-family: 'SFPro', sans-serif !important;
}

[class^="material-icons"] {
  font-family: 'Material Icons Outlined' !important;
  font-size: inherit !important;
}

html,
body {
  height: 100%;
}

html {
  height: 100%;
  width: 100%;
}

body {
  min-height: 100%;
  margin: 0;
  padding: 0;
}

.title-app {
  text-align: center;
  font-size: 1rem;
  margin: 0;
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-weight: 500;
  color: rgb(98, 98, 98);
  letter-spacing: 2px;
  text-transform: capitalize;
}

.apps {
  padding: 64px 24px;
  gap: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.app {
  width: 64px;
  height: 64px;
  font-size: 32px;
  color: #FFF;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #82b29a;
  border-radius: 16px;
}

.add-app:hover {
  background-color: #B39DDB;
}

.app-container {
  height: fit-content;
}

.app-container-active {
  position: relative;
  z-index: 0;
}

div[class^='app-container-'] {
  cursor: pointer;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.menu-container {
  background-color: #f2cc8e;
  padding: 32px 24px;
  margin: 32px 0;
  position: absolute;
  display: grid;
  grid-template-columns: repeat(auto-fill, 50% 50%);
  gap: 16px;
  border-radius: 12px;
  left: 24px;
  width: calc(100% - 48px);
  font-weight: 700;
}

.app-container-active::before {
  content: "";
  position: absolute;
  height: 96px;
  width: 96px;
  bottom: -120px;
  left: -15px;
  background-color: #f2cc8e;
  clip-path: polygon(0 0, 100% 0, 100% 100%);
  transform: rotate(-45deg);
  z-index: -1;
}

.menu {
  font-size: 12px;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 24px;
}

.setting-profile-header {
  font-size: 24px;
  text-decoration: underline;
  cursor: pointer;
}

.header-flex {
  gap: 16px;
  margin: 36px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
}

.back-button {
  border-radius: 50%;
  border: 2px solid #000;
  padding: 8px;
  display: flex;
  align-items: center;
  cursor: pointer;
  position: fixed;
  top: 1rem;
  left: 1rem;
}

.back-button img {
  height: 16px;
}

.profile-flex {
  padding: 24px 48px;
}

@media (max-width:580px) {
  .menu-container {
    justify-content: center;
  }

  .menu {
    margin: 0 auto;
  }
}


.login-image {
  max-height: 50vh;
}

.apps-wrapper {
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

.bg {
  animation: slide 3s ease-in-out infinite alternate;
  background-image: linear-gradient(-60deg, #8EC5FC 50%, #E0C3FC 50%);
  bottom: 0;
  left: -50%;
  opacity: .5;
  position: fixed;
  right: -50%;
  top: 0;
  z-index: -1;
}

.bg2 {
  animation-direction: alternate-reverse;
  animation-duration: 4s;
}

.bg3 {
  animation-duration: 5s;
}

.login {
  background-color: rgba(255, 255, 255, .8);
  border-radius: .25em;
  box-shadow: 0 0 .25em rgba(0, 0, 0, .25);
  padding: 10vmin;
  position: fixed;
  text-align: center;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.user-button {
  border-radius: 50%;
  height: 32px;
  width: 32px;
  font-size: 18px;
  text-align: center;
  display: flex;
  line-height: 36px;
  justify-content: center;
  cursor: pointer;
  text-shadow: 0 0 2px rgb(255 255 255);
  cursor: pointer;
}

.user-name {
  font-size: 18px;
  margin-bottom: -7px;
  cursor: pointer;
}

@keyframes slide {
  0% {
    transform: translateX(-25%);
  }

  100% {
    transform: translateX(25%);
  }
}

.title {
  font-size: 24px;
  font-family: 'Beau Rivage', cursive;
}

.subtitle {
  font-size: 14px;
}

.popup-profile {
  position: absolute;
  background-color: rgba(255, 255, 255, .8);
  border-radius: .25em;
  box-shadow: 0 0 .25em rgba(0, 0, 0, .25);
  top: 64px;
  right: 12px;
  padding: 24px;
  z-index: 999;
}

.popup-feature {
  font-size: 24px;
  cursor: pointer;
}

.popup-menu {
  font-size: 14px;
}

.sidenav-icons {
  height: 20px;
  width: 20px;
  object-fit: contain;
}

.wrapper {
  min-height: 100vh;
}

.loader-wrapper {
  position: fixed;
  top: calc(50% - 40px);
  left: calc(50% - 40px);
  height: 100vh;
  width: 100vw;
  z-index: 9999;
}

.loader {
  border: 10px solid #f3f3f3;
  border-top: 10px solid #3498db;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.img-thumbnail-pagination {
  width: 48px;
  height: 48px;
  object-fit: contain;
  cursor: pointer;
}

.img-thumbnail-pagination.forbidden:hover {
  cursor: not-allowed;
}

.card-header {
  border-bottom: none;
}

div[id^="select-"] {
  padding: 7.07px 12px;
}

.autocomplete-error svg {
  display: none;
}

.c-pointer {
  cursor: pointer;
}

input[type='number'] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.customized-daterange-picker {
  position: absolute;
  background-color: #FFF;
  width: fit-content;
  right: -16px;
  top: calc(100% - 72px);
  z-index: 999;
  border-radius: 8px;
  box-shadow: 0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%);
  padding-bottom: 16px;
  max-width: 90vw;
}

.MuiFormLabel-filled+.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline legend,
.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline legend {
  padding-right: 4px;
  padding-left: 4px;
  font-size: 9px !important;
  font-weight: 400;
  width: fit-content;
}

.MuiOutlinedInput-notchedOutline legend>span {
  padding-right: 0;
  padding-left: 0;
  width: fit-content;
  letter-spacing: normal;
  font-weight: 400;
  font-family: 'SFPro' !important;
  font-size: 9.3px;
}

.MuiTable-root * {
  color: #344767 !important;
  font-weight: 700;
}

.MuiTable-root p {
  margin-bottom: 0;
}

.Toastify>.Toastify__toast-container,
#unAuthorized {
  max-width: 90vw;
  width: 450px;
}

#unAuthorized {
  background-color: red;
  color: #FFF;
  padding: 16px 24px;
  border-radius: 8px;
}

#unAuthorized .Toastify__close-button {
  opacity: 1;
}

#unAuthorized svg {
  fill: #FFF;
}

.text-bold {
  font-weight: 700;
}

.MuiOutlinedInput-root.MuiInputBase-sizeSmall .MuiAutocomplete-input {
  padding: 4px 4px 4px 6px !important;
}

.MuiBreadcrumbs-li {
  line-height: 1.2rem !important;
}

.hide-in-desktop {
  display: none;
}

.customized-editor>div {
  height: 100%;
}

.ql-container {
  height: auto !important;
  height: calc(100% - 48px) !important;
}

.ql-editor {
  height: auto !important;
}

#demo-controlled-radio-buttons-group {
  color: #344767;
}

.voucher-date-picker {
  top: 64px;
  max-width: fit-content;
  right: calc(50% - 169px);
}

.ql-editor {
  height: 140px !important;
}

.readonly-quill .ql-toolbar {
  display: none;
}

.readonly-quill .ql-toolbar.ql-snow+.ql-container.ql-snow {
  border-top: 1px solid #ccc;
}

.readonly-quill .ql-container.ql-disabled {
  border-radius: 0.375rem 0.375rem 0.375rem 0.375rem;
}

.MuiSelect-select {
  min-height: 1.266rem !important;
}

.qty_transfer-container {
  position: absolute;
  height: calc(200% - 24px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.add-product-inventory .MuiGrid-grid-lg-3 {
  max-width: 116px !important;
}

@media (max-width:1100px) {
  .grid-modal .MuiGrid-container {
    padding-right: 24px;
  }
}

@media (max-width:991px) {
  [role=form]:not(.grid-modal)>.MuiGrid-container {
    width: 100%;
    margin: -24px auto 0 auto;
  }

  .grid-modal .MuiGrid-item {
    padding-left: 0 !important;
  }

  .multiple-images-grid {
    margin: 0 auto 0 auto !important;
    padding-right: 0;
  }

  .multiple-images-grid>.MuiGrid-item {
    padding-right: 32px;
    padding-left: 0 !important;
  }

  .multiple-images-grid .MuiCard-root {
    margin: 0 auto;
  }

  .qty_transfer-container {
    position: relative;
    padding-right: 0;
    height: 100%;
  }

  .add-product-inventory .MuiGrid-grid-lg-3 {
    max-width: 50% !important;
  }
}

@media (max-width:768px) {
  .TabsListUnstyled-root {
    display: flex;
    align-items: center;
  }

  .TabsListUnstyled-root>button {
    padding: 8px;
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    width: calc(100% - 24px);
  }
}

@media (max-width:767px) {
  .login {
    max-width: 100%;
  }

  .login-image {
    width: 200px;
  }

  .apps {
    justify-content: space-evenly;
  }

  .search-input>.MuiInputLabel-root {
    width: calc(100% - 64px);
  }

  .MuiFormLabel-filled+.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline legend,
  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline legend {
    width: fit-content;
    max-width: calc(100% - 72px);
  }

  .tablist-customized {
    width: 200% !important;
  }
}

@media (max-width:576px) {
  .hide-in-desktop {
    display: block;
  }

  .hide-in-mobile {
    display: none;
  }

  .customized-editor>div {
    height: auto;
  }

  .multiple-images-grid {
    margin: 0 auto !important;
    margin-top: 36px !important;
    padding-right: 0;
    gap: 36px;
    width: 100% !important;
  }

  .multiple-images-grid .MuiGrid-item {
    padding: 0 !important;
    margin: 0 auto;
  }
}

/* print */
@media screen {
  .print-item {
    display: none !important;
  }

  .hide-when-print {
    display: block !important;
  }
}

@media print {
  .print-item {
    display: grid !important;
  }

  .hide-when-print {
    display: none !important;
  }
}

.flex-center-col {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.table-contoh>* {
  border: 1px solid black;
}

.add-to-print {
  background-color: transparent;
  text-decoration: underline;
  border: none;
}

.layout-center {
  margin-top: 2rem;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.barcode-item {
  overflow: hidden;
  border: 1px solid gray;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 5px;
  display: flex;
}

.layout-print-paper {
  left: 0;
  top: 0;
  height: auto;
  box-sizing: border-box;
  position: relative;
}

.layout-print-barcodes {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  margin: 0 !important;
  padding: 0 !important;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  background-color: white;
  /* grid-template-rows: min-content; */
  box-sizing: border-box;
  /* border: .1px solid rgb(255, 23, 23); */
}

.max-line-4 {
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 4;
  /* number of lines to show */
  line-clamp: 4;
  /* -webkit-box-orient: vertical; */
}

@media print {

  html,
  body {
    margin: 0 !important;
    padding: 0 !important;
    overflow: hidden;
  }
}

@media print {

  .print-display-none,
  .print-display-none * {
    display: none !important;
  }

  .print-visibility-hide,
  .print-visibility-hide * {
    visibility: hidden !important;
  }

  .printme,
  .printme * {
    visibility: visible !important;
  }

  .printme {
    position: absolute;
    left: 0;
    top: 0;
  }

}

.pointer {
  cursor: pointer;
}

@media print {
  .page-break {
    margin-top: 1rem;
    display: block;
    break-after: always;
  }

  .print {
    opacity: 1;
    height: auto;
  }

  .page-break-before {
    break-before: always;
    break-after: always;
    display: block;
    page-break-after: avoid;
  }
}

@media screen {
  .print {
    opacity: 0;
    height: 1px;
    overflow: hidden;
  }
}


@media print {

  html,
  body {
    height: auto !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
  }
}

/* print letter DO */
.do-container {
  margin: 0;
  width: 100%;
  height: auto;
  background-color: white;
  padding: 1rem;
}

.do-container .do-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.do-header-letter h2 {
  font-size: 2.4rem;
  font-weight: 600;
}

.do-title {
  font-size: 1.7rem;
  margin: 0;
  text-transform: uppercase;
}

.do-address {
  margin: 0;
  text-transform: capitalize;
  font-size: .8rem;
  width: 450px;
}

.do-header-line {
  width: 100%;
  height: 3px;
  background-color: rgb(143, 143, 143);
  margin-bottom: 3px;
  margin-top: 5px;
}

.do-header-line-2 {
  width: 100%;
  height: 3px;
  background-color: rgb(143, 143, 143);
  margin-bottom: 10px;
}

.do-body-header {
  display: flex;
  justify-content: space-between;
}

.do-body-header-right table th {
  min-width: 100px;
  display: flex;
}

.do-yth {
  font-size: 1.4rem;
  font-weight: 600;
}

.do-body-tabel {
  display: flex;
  flex-direction: column;
}

.do-body-tabel table {
  margin-top: 2rem;
  width: 100%;
}

.do-body-tabel table th,
.do-body-tabel table td {
  font-size: 1rem;
  text-align: center;
  border: 1px solid rgb(179, 179, 179);
}

.do-body-tabel .do-text {
  text-align: left;
  padding-left: 5px;
}

.do-body-tabel .do-number {
  text-align: right;
  padding-right: 5px;
}

.do-body-tabel .do-pages {
  align-self: flex-end;
  font-size: 1rem;
  margin-top: 10px;
}

.do-body-sign {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
}

.sign-template {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sign-template .sign-title {}

.sign-template .sign {
  font-size: 1.5rem;
}

.title-tabel {
  min-width: 100px;
  display: flex;
  font-weight: 600;
}

.do-body-sign .note {
  flex-basis: 30%;
}

.subcategory-detail-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-right: 4rem;
}

.subcategory-detail-container img {
  height: 200px;
}

.button-accessories {
  width: 100px;
  height: 100px;
  border-radius: 10px;
  overflow: hidden;
}

.button-accessories img {
  width: 100%;
  height: 100%;
}

.subcategory-detail-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.card-product {
  background-color: white;
  align-items: flex-start;
  white-space: normal;
  padding: 0;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
  border: 1px solid rgb(192, 192, 192);
  height: 100%;
  cursor: pointer;
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  transform: translateY(-50);
}

.card-product .image {
  height: 150px;
  overflow: hidden;
  border-radius: 10px 10px 0 0;
  position: relative;
  background-color: rgb(187, 187, 187);
}

.card-product .image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.card-product .body {
  padding: 10px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}